import React, { useEffect, useState } from "react"
import { debounce } from "lodash"
import Swiper from "react-id-swiper"
import "swiper/scss"
import LayerPopupLayout from "./LayerPopupLayout"
import img_swiper_left from "../images/img_swiper_left.svg"
import img_swiper_right from "../images/img_swiper_right.svg"
import temp_img from "../images/temp_img.svg"
import temp_img2 from "../images/temp_img2.svg"
import UserContainer from "../containers/UserContainer"
import Validator from "validator"
import MyContainer from "../containers/MyContainer"
import SwipeableViews from "react-swipeable-views"
import img_popup_profile_left from "../images/img_popup_profile_left.svg"
import img_popup_profile_right from "../images/img_popup_profile_right.svg"
import { dataCheckFn } from "../utils/util"
import StudyItem from "../components/common/StudyItem"
import { Desktop, Mobile } from "../components/layouts/mediaQuery"
import { useDispatch } from "react-redux"
import { setMyNotice } from "../store/modules/my"
const NoticePopup = ({ getNotice, myNotice, open, onClose, data = {} }) => {
  const dispatch = useDispatch()
  const noticesTypeName = {
    NEWS: "News",
    EVENT: "EVENT",
    POLICY: "정책변경",
    SYSTEM: "시스템",
    EMERGENCY: "긴급",
    FAQ: "자주하는 질의응답",
  }
  const [checkData, setCheckData] = useState(false)
  const closePopup = () => {
    onClose()
  }

  const getData = async () => {
    await getNotice({ noticeSeq: data.noticeSeq })
  }

  useEffect(() => {
    if (open) {
      getData()
    } else {
      dispatch(setMyNotice({}))
    }
  }, [open])

  useEffect(() => {
    setCheckData(dataCheckFn(myNotice))
  }, [myNotice])
  return (
    <LayerPopupLayout open={open} onClose={closePopup} isMCloseBtn transparency zIndex={102}>
      <div className="popup-notice-detail-container">
        <p className="title">공지사항 상세보기</p>
        <div className="popup-notice-detail-inner">
          {checkData && (
            <Desktop>
              <div className="popup-notice-detail-inner-top">
                <div className="popup-notice-detail-inner-top-left">
                  <p className="popup-notice-detail-inner-top-left-type">{noticesTypeName[myNotice.type]}</p>
                  <p>{myNotice.subject}</p>
                </div>
                <p className="popup-notice-detail-inner-top-date">{myNotice.regDatetime?.split(" ")[0]}</p>
              </div>
            </Desktop>
          )}
          {checkData && (
            <Mobile>
              <div className="popup-notice-detail-inner-top">
                <div className="popup-notice-detail-inner-top-left">
                  <p className="popup-notice-detail-inner-top-left-type">{noticesTypeName[myNotice.type]}</p>
                  <p className="popup-notice-detail-inner-top-date">{myNotice.regDatetime?.split(" ")[0]}</p>
                </div>
                <p>{myNotice.subject}</p>
              </div>
            </Mobile>
          )}
          {checkData && (
            <Desktop>
              {myNotice.imageUrlPc && <img style={{ maxWidth: "100%", objectFit: "cover" }} src={myNotice.imageUrlPc}></img>}
              {checkData && <pre className="popup-notice-detail-inner-contents">{myNotice.content}</pre>}
            </Desktop>
          )}
          {checkData && (
            <Mobile>
              <div className="popup-notice-detail-inner-contents-div">
                {myNotice.imageUrlMobile && <img style={{ maxWidth: "100%", objectFit: "cover" }} src={myNotice.imageUrlMobile}></img>}
                {checkData && <pre className="popup-notice-detail-inner-contents">{myNotice.content}</pre>}
              </div>
            </Mobile>
          )}
        </div>
        {/* button */}
        <div className="button-view">
          <button className="btn btn-primary font-white send-button check-btn" onClick={onClose}>
            확인
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default UserContainer(MyContainer(NoticePopup))
