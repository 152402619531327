/*
  마이렛미업 공지사항 페이지
*/
import React, { useCallback, useEffect, useState } from "react"
import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import LeftSideBar from "../../components/my/LeftSideBar"
import RightSideBar from "../../components/my/RightSideBar"
import PrivateRoute from "../../components/privateRoute"
import MyContainer from "../../containers/MyContainer"
import { Desktop, Mobile } from "../../components/layouts/mediaQuery"
import NoticePopup from "../../popups/NoticePopup"
import { rnMessageListener } from "../../utils/util"
import { navigate } from "gatsby-link"

//공지사항
const COUNT = 20
const Notice = props => {
  const { getNoticesAdd, myNoticesList, myNoticesListTotal, getNotices } = props
  const [filterKind, setFilterKind] = useState(0)
  const [page, setPage] = useState(0)
  const [openNoticeDetail, setOpenNoticeDetail] = useState({ open: false, data: {} })
  const [noticesType, setNoticesType] = useState(["전체", "NEWS", "EVENT", "POLICY", "SYSTEM", "EMERGENCY", "FAQ"])
  const [noticesTypeName, setNoticesTypeName] = useState({
    NEWS: "News",
    EVENT: "EVENT",
    POLICY: "정책변경",
    SYSTEM: "시스템",
    EMERGENCY: "긴급",
    FAQ: "자주하는 질의응답",
  })
  // get data
  const getData = async () => {
    let temp = { type: filterKind == 0 ? "" : noticesType[filterKind], count: COUNT, page: 0 }

    await getNotices(temp)
  }

  useEffect(() => {
    setPage(0)
    getData()
  }, [filterKind])

  //더보기
  const increasePage = async () => {
    //add pointLogs
    let temp = { type: filterKind == 0 ? "" : noticesType[filterKind], count: COUNT, page: page + 1, myNoticesList: myNoticesList }
    await getNoticesAdd(temp)
    // totalCount 조건
    if (COUNT * (page + 1) < myNoticesListTotal) setPage(prev => prev + 1)
  }

  const messageDataFn = useCallback(e => {
    let data = JSON.parse(e.data)
    if (data.type == "BACK") navigate(-1)
  }, [])

  useEffect(() => {
    if (window.ReactNativeWebView) {
      document.addEventListener("message", messageDataFn)
    }
    return () => {
      document.removeEventListener("message", messageDataFn)
    }
  }, [])
  const noticeFn = () => {
    if (props.location.search == "" || props.location.search == undefined) return false
    let temp = props.location.search
    let tempArr = temp.split("&")
    let seq = null
    for (let i of tempArr) {
      if (i.indexOf("seq") != -1) {
        seq = i.split("=")[1]
      }
    }
    if (seq) {
      setOpenNoticeDetail({ open: true, data: { noticeSeq: seq } })
    }
  }
  useEffect(() => {
    if (props.location.search) noticeFn()
  }, [])

  return (
    <Layout title="공지사항">
      <SEO title="마이렛미업" />
      <div className="d-flex justify-content-center ">
        <LeftSideBar />
        <div className="container my-container">
          <NoticePopup open={openNoticeDetail.open} onClose={() => setOpenNoticeDetail({ data: {}, open: false })} data={openNoticeDetail.data} />
          <p className="my-point-title">공지사항</p>

          <div className="my-point-history-view">
            <div className="filter-view">
              <div></div>

              <select className="form-select" value={filterKind} onChange={e => setFilterKind(e.target.value)}>
                <option value={0}>전체</option>
                <option value={1}>(PR) News</option>
                <option value={2}>EVENT안내</option>
                <option value={3}>정책변경 안내</option>
                <option value={4}>시스템 안내</option>
                <option value={5}>긴급공지 안내</option>
                <option value={6}>자주하는 질의응답</option>
              </select>
            </div>
            <div className="list-view-wrapper">
              <Desktop>
                <p className="total-count">총 {myNoticesListTotal}개</p>
              </Desktop>
              <div className="list-view">
                <Desktop>
                  {myNoticesList
                    .filter((i, idx) => idx < COUNT * (page + 1))
                    .map((i, idx) => (
                      <div onClick={() => setOpenNoticeDetail({ open: true, data: i })} className="list-item" key={idx}>
                        <div className="list-item-no-title-content-view">
                          <p className="list-item-type">{noticesTypeName[i.type]}</p>
                          <div className="list-item-title-content-view">
                            <div className="list-item-title-view">
                              <p className="list-item-title">{i.subject}</p>
                            </div>

                            <p className="list-item-content"></p>
                          </div>
                        </div>
                        <p className="list-item-date">{i.regDatetime}</p>
                      </div>
                    ))}
                </Desktop>
                <Mobile>
                  {myNoticesList
                    .filter((i, idx) => idx < COUNT * (page + 1))
                    .map((i, idx) => (
                      <div
                        onClick={() => setOpenNoticeDetail({ open: true, data: i })}
                        className="list-item"
                        key={idx}
                        style={{ flexDirection: "column", justifyContent: "flex-start" }}
                      >
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          <div className="list-item-no-title-content-view">
                            <p className="list-item-type">{noticesTypeName[i.type]}</p>
                            <div className="list-item-title-content-view">
                              <p className="list-item-content"></p>
                            </div>
                          </div>
                          <p className="list-item-date">{i.regDatetime}</p>
                        </div>
                        <p className="list-item-title">{i.subject}</p>
                      </div>
                    ))}
                </Mobile>
              </div>
            </div>
            <div className={`more-see ${COUNT * (page + 1) < myNoticesListTotal ? "active" : ""}`}>
              <button className="btn btn-primary font-white" onClick={increasePage}>
                더보기
              </button>
            </div>
          </div>
        </div>
        <RightSideBar />
      </div>
    </Layout>
  )
}

export default MyContainer(Notice)
